<template>
    <div class="homeContainer">
        <el-container>
            <el-header>
                <i class="el-icon-s-home" style="font-size:18px"></i><span style="font-size:14px;cursor:pointer" @click="logout">退出</span>
            </el-header>
            <el-container>
                <el-aside style="width:240px">
                    <el-menu :router="true">
                        <el-menu-item index="1" :route="{path:'/upload'}">视频上传</el-menu-item>
                        <el-menu-item index="2" :route="{path:'/videos'}">我的视频</el-menu-item>
                    </el-menu>
                </el-aside>
                <el-main>
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import { removeUserId } from '../utils/store'
export default {
    data(){
        return{

        }
    },
    mounted(){
      
    },
    methods:{
        logout(){
            removeUserId()
            location.reload()
        }
    }
}
</script>

<style>
.el-header{
    background-color: #409EFF;
    color: #333;
    line-height: 60px;
    text-align: right;
}

</style>